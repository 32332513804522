<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "ViewFilters.PageFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons @methodSave="onSubmit('btn-save')" :isNewRecord="false" />
    </div>
  </div>
  <form class="form-edit-page-filter pe-2 ps-2" novalidate>
    <div class="row">
      <div v-if="errors.length > 0" class="alert alert-warning">
        <ul class="mb-0">
          <li v-for="error in errors" v-bind:key="error">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-1 mb-2">
      <div class="col-md-12">
        <div class="form-check">
          <label for="IsPageFilterCollapsed" class="form-label">{{
            $t(
              "ViewFilters.IsPageFilterCollapsed",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsPageFilterCollapsed"
            v-model="pageFilterData.isPageFilterCollapsed"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-warning table-filter">
          <thead>
            <tr>
              <th class="text-left">
                {{
                  $t(
                    "ViewFilters.CriteriaNo",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
              <th class="col-md-4">
                <label class="required">
                  {{
                    $t(
                      "ViewFilters.CriteriaField",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
              </th>
              <th class="col-md-4">
                <label class="required">
                  {{
                    $t(
                      "ViewFilters.CriteriaOperator",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
              </th>
              <th class="text-center col-md-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-middle">
                <span class="badge bg-primary rounded-pill fs-6">{{
                  this.getCriteriaNextRowNumber()
                }}</span>
              </td>
              <td>
                <FormSelect
                  type="SelectWithLocalData"
                  @onChange="onChangeField"
                  :data="fields"
                />
              </td>
              <td>
                <FormSelect
                  type="SelectWithLocalData"
                  @onChange="onChangeFieldOperator"
                  :data="fieldOperators"
                  :resetSelect="operatorValueReset"
                  :isDisabled="String.isNullOrWhiteSpace(selectedField)"
                />
              </td>
              <td class="align-middle">
                <button
                  type="button"
                  class="btn btn-primary w-100 h-41"
                  @click="add"
                >
                  <i class="bi bi-plus"></i>
                  {{
                    $t(
                      "ViewFilters.Add",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="mt-2 mb-2 ms-0 ps-0" v-if="!pageFilterData.criterias.length">
          <label class="required">
            {{
              $t(
                "ViewFilters.PageCriteriaNotFound",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label
          >
        </p>
        <div class="table-responsive" v-else>
          <table
            class="table table-filter table-striped table-bordered table-primary table-hover"
          >
            <thead>
              <tr>
                <th class="text-left">
                  {{
                    $t(
                      "ViewFilters.CriteriaNo",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th class="col-md-3">
                  <label class="">
                    {{
                      $t(
                        "ViewFilters.CriteriaField",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </th>
                <th class="col-md-3">
                  <label class="">
                    {{
                      $t(
                        "ViewFilters.CriteriaOperator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </th>
                <th class="col-md-3 text-center">
                  <label class="">
                    {{
                      $t(
                        "Buttons.Actions",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="criteria-item"
                :data-number="item.number"
                v-for="item in pageFilterData.criterias"
                :key="item.number"
              >
                <td class="align-middle">
                  <span class="badge bg-primary rounded-pill fs-6">{{
                    item.number
                  }}</span>
                </td>
                <td class="align-middle" :data-value="item.fieldPublicId">
                  {{ item.fieldName }}
                </td>
                <td class="align-middle" :data-value="item.operatorPublicId">
                  {{ item.operatorName }}
                </td>
                <td class="align-middle" align="center">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="remove(item.number)"
                  >
                    {{
                      $t(
                        "Buttons.Delete",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
export default {
  name: "CustomObjectViewFilterEditPageFilter",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      operatorValueReset: false,
      pageFilterData: {
        customObjectPublicId: this.$route.params.customObjectId,
        viewFilterPublicId: this.$route.params.viewFilterId,
        criterias: [],
      },
      queryBuilderFieldsDetail: [],
      selectedField: null,
      selectedFieldName: null,
      selectedOperator: null,
      selectedeOperatorName: null,
      fields: [],
      fieldOperators: [],
    };
  },
  mounted() {
    this.getQueryBuilderFields();
  },
  methods: {
    add() {
      if (
        String.isNullOrWhiteSpace(this.selectedField) ||
        String.isNullOrWhiteSpace(this.selectedOperator)
      ) {
        createToast(
          this.$t(
            "ViewFilters.FieldOrOperatorNotValid",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      if (this.pageFilterData.criterias.length >= 11) {
        createToast(
          this.$t(
            "ViewFilters.MaxCriteriaItem",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var params = {
        number: this.getCriteriaNextRowNumber(),
        fieldPublicId: this.selectedField,
        fieldName: this.selectedFieldName,
        operatorPublicId: this.selectedOperator,
        operatorName: this.selectedOperatorName,
        value: "",
        text: "",
      };

      var valid = true;
      $.each(this.pageFilterData.criterias, function (i, v) {
        if (
          params.fieldPublicId === v.fieldPublicId &&
          params.operatorPublicId === v.operatorPublicId
        ) {
          valid = false;
          return;
        }
      });
      if (!valid) {
        createToast(
          this.$t(
            "ViewFilters.DuplicateCriteriaItems",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.operatorValueReset = !this.operatorValueReset;
      this.pageFilterData.criterias.push(params);
    },
    remove(number) {
      this.pageFilterData.criterias = this.pageFilterData.criterias.filter(
        function (item) {
          return item.number !== number;
        }
      );

      var index = 0;
      this.pageFilterData.criterias.forEach((element) => {
        element.number = ++index;
      });
    },
    getCriteriasMaxNumber() {
      var maxNumber = 0;
      $.each(this.pageFilterData.criterias, function () {
        if (this.number > maxNumber) {
          maxNumber = this.number;
        }
      });

      return maxNumber;
    },
    onChangeField(selected) {
      this.operatorValueReset = !this.operatorValueReset;
      this.fieldOperators = [];

      this.selectedField = selected.key;
      this.selectedFieldName = selected.value;

      if (!String.isNullOrWhiteSpace(this.selectedField)) {
        var currentFieldOperators = this.queryBuilderFields.find(
          (f) => f.id === this.selectedField
        ).operators;
        if (currentFieldOperators.length) {
          currentFieldOperators.map((x) => {
            var obj = { key: x.value, value: x.key };
            this.fieldOperators.push(obj);
          });
        }
      }
    },
    onChangeFieldOperator(selected) {
      this.selectedOperator = selected.key;
      this.selectedOperatorName = selected.value;
    },
    getQueryBuilderFields() {
      this.queryBuilderFields = [];
      this.fields = [];

      var self = this;
      this.$root
        .asyncRequest(
          `/Lcdp-FieldListQueryBuilder?customObjectPublicId=${this.$route.params.customObjectId}&isAddFake=true`
        )
        .then((response) => {
          response.data.items.map((x) => {
            self.queryBuilderFields.push({
              formula: x.formulaName,
              label: x.name,
              id: x.publicId,
              fieldType: x.fieldType,
              operators: self.$root.localizationForQueryBuilderFieldOperators(
                x.operators.items
              ),
            });
            self.fields.push({
              key: x.publicId,
              value: x.name,
            });
          });

          this.getPageFilter();
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getCriteriaNextRowNumber() {
      return this.getCriteriasMaxNumber() + 1;
    },
    getPageFilter() {
      this.$prodGatewayAxios
        .get(
          `/Lcdp-ViewFilterGetPageFilter?id=${this.$route.params.viewFilterId}`
        )
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.pageFilterData = response;
          }
        })
        .catch((r) => {
          this.errors.push(r);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-edit-page-filter");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.pageFilterData.customObjectPublicId =
        this.$route.params.customObjectId;
      this.pageFilterData.viewFilterPublicId = this.$route.params.viewFilterId;
      this.$prodGatewayAxios
        .post("/Lcdp-ViewFilterEditPageFilter", { ...this.pageFilterData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/ViewFilters/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
